import { type UserRoles } from '@bibeltv-leseplaene/api'
import { type RouteLocationNormalized } from 'vue-router'

export async function checkUserAuth(allowedRoles: UserRoles[]) {
  const auth = useAuth()
  const keycloak = useKeycloak()

  await keycloak.waitForKeycloak()
  await auth.waitForAuth()

  if (
    !auth ||
    !auth.session.user ||
    !allowedRoles.some(
      (allowedRole) => auth.session.user?.roles.some((role) => role.name === allowedRole),
    )
  ) {
    return false
  }
  return true
}

export function getRedirectLocation() {
  const auth = useAuth()

  if (auth.session.status === 'unauthenticated') return '/login'
  if (auth.session.isAdmin) return '/users'
  else return '/'
}

export async function enforceAuth(route: RouteLocationNormalized) {
  if (Array.isArray(route.meta.auth) && !(await checkUserAuth(route.meta.auth as UserRoles[]))) {
    return navigateTo(getRedirectLocation())
  }
}

export default defineNuxtRouteMiddleware(async (to) => {
  return await enforceAuth(to)
})
